import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-maint-constants',
  templateUrl: './maint-constants.component.html',
  styleUrls: ['./maint-constants.component.scss']
})
export class MaintConstantsComponent implements OnInit {
  constants:Constants[];
  constructor(private api:ApiService) { }

  ngOnInit() {
    this.constants = new Array<Constants>();
    this.api.getAllConstants().subscribe((response:any)=>{
      for(let i=0;i<response.value.length;i++)
      {
        let cons = new Constants();
        cons.sno = response.value[i].sno;
        cons.param = response.value[i].param;
        cons.varname = response.value[i].varname;
        cons.value = response.value[i].value;
        this.constants.push(cons);
      }
    });
  }

}

export class Constants{
sno:number;
varname:string;
param:string;
value:string;
}
