import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ProductsComponent } from './products/products.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ApiService } from './api.service';
import { HttpClientModule } from '@angular/common/http';
import { MaintainProductsComponent } from './maintain-products/maintain-products.component';
import { AddProductComponent } from './add-product/add-product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditProductComponent } from './edit-product/edit-product.component';
import { MaintConstantsComponent } from './maint-constants/maint-constants.component';
import { EditConstantComponent } from './edit-constant/edit-constant.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    ProductsComponent,
    MaintainProductsComponent,
    AddProductComponent,
    EditProductComponent,
    MaintConstantsComponent,
    EditConstantComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy },
  ApiService],
  bootstrap: [AppComponent]
})
export class AppModule {
  
 }
