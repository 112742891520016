import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddProductComponent } from './add-product/add-product.component';
import { EditConstantComponent } from './edit-constant/edit-constant.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MaintConstantsComponent } from './maint-constants/maint-constants.component';
import { MaintainProductsComponent } from './maintain-products/maintain-products.component';
import { ProductsComponent } from './products/products.component';


const routes: Routes = [
  {
    path:'',
    component:HomePageComponent
},
{
  path:'services',
  component:HomePageComponent
},
{
  path:'about',
  component:HomePageComponent
},
{
  path:'products',
  component:ProductsComponent
},
{
  path:'maintproducts',
  component:MaintainProductsComponent
},
{
  path:'addproduct',
  component:AddProductComponent
},
{
  path:'editproduct/:id',
  component:EditProductComponent
},
{
  path:'maintconstants',
  component:MaintConstantsComponent

},
{
  path:'editconstants',
  component:EditConstantComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
