import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { selOpt } from '../add-product/add-product.component';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
  categoryList:selOpt[];
  productForm:FormGroup;
  productImage:string;
  imageUrl:string;
  fileData: File = null;
  constructor(private api:ApiService,private route:ActivatedRoute) { }

  ngOnInit() {
    this.imageUrl = environment.imgUrl;
    this.productForm = new FormGroup({
      
      productName: new FormControl('', [Validators.minLength(2),Validators.required]),
      description: new FormControl('', [Validators.minLength(10),Validators.required,Validators.maxLength(10)]),
      dline1: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      dline2: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      dline3: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      dline4: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      category: new FormControl('', [Validators.required,Validators.maxLength(30)]),
      status: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      
    });
    this.categoryList = Array<selOpt>();
    let cat = new selOpt();
    cat.key = "1";
    cat.value = "cat1";
    this.categoryList.push(cat);
    cat = new selOpt();
    cat.key = "2";
    cat.value = "cat2";
    this.categoryList.push(cat);
    this.getProduct();
  }
  fileProgress(fileInput: any) {
    console.log(fileInput);
    this.fileData = <File>fileInput.target.files[0];
  }

  editImage()
  {
    let prodid = this.route.snapshot.paramMap.get('id');
    const formData = new FormData();
    formData.append('file', this.fileData);
    formData.append('function', 'EDITPRDIMAGE');
    formData.append('id', prodid);
    this.api.editPrdImage(formData).subscribe((response:any)=>{
      if(response.result)
      {
        alert("Image Updated");
        window.location.reload();
      }
      else
      {
        alert("Error");
      }
    });
  }

  editProduct(formValue)
  {
    let prodid = this.route.snapshot.paramMap.get('id');
    this.api.editProduct(prodid,formValue).subscribe((response:any)=>{
      if(response.result)
      {
        alert("Update Success");
      }
      else
      {
        alert("Error");
      }
    });
  }
  getProduct()
  {
    let prodid = this.route.snapshot.paramMap.get('id');
    this.api.getProductbyId(prodid).subscribe((response:any)=>{
      this.productForm.controls['productName'].setValue(response.value[0].productname);
      this.productForm.controls['description'].setValue(response.value[0].description);
      this.productForm.controls['dline1'].setValue(response.value[0].dline1);
      this.productForm.controls['dline2'].setValue(response.value[0].dline2);
      this.productForm.controls['dline3'].setValue(response.value[0].dline3);
      this.productForm.controls['dline4'].setValue(response.value[0].dline4);
      this.productForm.controls['category'].setValue(response.value[0].categoryid);
      this.productForm.controls['status'].setValue(response.value[0].status);
      this.productImage = response.value[0].imageName;
    });
  }

}
