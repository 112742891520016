import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  categoryList:Category[];
  loadProduct=false;
  imgUrl:string;
  constructor(private api:ApiService) {
    console.log(api);
    this.categoryList = new Array<Category>();
    
   }

  ngOnInit() {
    this.imgUrl = environment.imgUrl;
    this.api.getAllProducts().subscribe((response:any)=>{
      if(response.result)
      {
        
        for(let i=0;i<response.value.length;i++)
        {
          let category = new Category();
          category.products=[];
          category.categoryId = response.value[i].categoryId;
          category.categoryName = response.value[i].categoryName;
          for(let j=0;j<response.value[i].products.length;j++)
          {
            let temp = response.value[i].products[j];
            let prd = new Product();
          prd.productId = temp.productid;
          prd.productName = temp.productname;
          prd.description = temp.description;
          prd.dline1 = temp.dline1;
          prd.dline2 = temp.dline2;
          prd.dline3 = temp.dline3;
          prd.imageUrl = temp.imageName;
          category.products.push(prd);


          }
          this.categoryList.push(category);
          
        }
        console.log(this.categoryList);
        
      }
      this.loadProduct = true;
      
    });
  }

}

export class Product
{
  productId:number;
  productName:string;
  description:string;
  dline1:string
  dline2:string
  dline3:string
  imageUrl:string;
  category?:string;
  status?:string;

}
export class Category
{
  products:Product[];
  categoryId:number;
  categoryName:string;
}