import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'krishnaClient';
  isHome:boolean;
  constructor(private router:Router)
  {
    router.events.subscribe((val) => {
      // see also 
      // console.log(val instanceof NavigationEnd) 
      let path = window.location.href;
      console.log(path);
      
      if(path.includes('products'))
      {
        this.isHome = false;
      }
      else{
      this.isHome = true;
      }
      console.log(this.isHome);
      
      
  });
  }

  
}
