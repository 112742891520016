import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-edit-constant',
  templateUrl: './edit-constant.component.html',
  styleUrls: ['./edit-constant.component.scss']
})
export class EditConstantComponent implements OnInit {
  productForm:FormGroup;
  varname:string;
  param:string;
  constructor(private api:ApiService) { }

  ngOnInit() {

    this.productForm = new FormGroup({
      
      sno: new FormControl({value:'',disabled: true}),
      varname: new FormControl({value:'',disabled: true}),
      param: new FormControl({value:'',disabled: true}),
      value: new FormControl('', [Validators.required,Validators.maxLength(30)]),
      
      
    });

    this.getConstant();

  }

  getConstant()
  {
    this.api.getConstantbyId(1).subscribe((response:any)=>{
      this.productForm.controls['sno'].setValue(response.value[0].sno);
      this.productForm.controls['varname'].setValue(response.value[0].varname);
      this.productForm.controls['param'].setValue(response.value[0].param);
      this.productForm.controls['value'].setValue(response.value[0].value);
      this.varname = response.value[0].varname;
      this.param = response.value[0].param;
    });

  }

  updateConstant(formValue)
  {
    this.api.updateConstant(formValue,this.varname,this.param).subscribe((response:any)=>{
      if(response.result)
      {
        alert("Updated");
      }
    });

  }

}
