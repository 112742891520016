import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgSelectOption, Validators } from '@angular/forms';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  categoryList:selOpt[];
  productForm:FormGroup;
  fileData: File = null;
  constructor(private api:ApiService) { }

  ngOnInit() {

    this.productForm = new FormGroup({
      
      productName: new FormControl('', [Validators.minLength(2),Validators.required]),
      description: new FormControl('', [Validators.required,Validators.maxLength(10)]),
      dline1: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      dline2: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      dline3: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      dline4: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      category: new FormControl('', [Validators.required,Validators.maxLength(30)]),
      status: new FormControl('', [Validators.minLength(2),Validators.required,Validators.maxLength(30)]),
      
    });

    this.categoryList = Array<selOpt>();
    let cat = new selOpt();
    cat.key = "1";
    cat.value = "cat1";
    this.categoryList.push(cat);
    cat = new selOpt();
    cat.key = "2";
    cat.value = "cat2";
    this.categoryList.push(cat);
  }

  fileProgress(fileInput: any) {
    console.log(fileInput);
    this.fileData = <File>fileInput.target.files[0];
  }

  addProduct(formValue)
  {

    console.log(formValue);
    const formData = new FormData();
    formData.append('file', this.fileData);
    console.log(formData);
    formData.append('function', "ADDPRODUCT"); 
    formData.append('productName',formValue.value.productName);
    formData.append('description',formValue.value.description);
    formData.append('dline1',formValue.value.dline1);
    formData.append('dline2',formValue.value.dline2);
    formData.append('dline3',formValue.value.dline3);
    formData.append('dline4',formValue.value.dline4);
    formData.append('category',formValue.value.category);
    formData.append('status',formValue.value.status);
    
    this.api.addProduct(formData).subscribe((response:any)=>{
      console.log(response);
      if(response.result)
      {
        alert("Product added successfully");
      }
      else
      {
        alert("Error");
      }
      
    });
    
  }

}

export class selOpt{
  key:string;
  value:string;
}