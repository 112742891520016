import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) { }

  getAllProducts()
  {
    var func =   {"function": "GETALLPRODUCTS"};
    // Object.assign(func,{"categoryIdArr":catArr}); 
     return this.http.post(environment.endPoint,func);
  }
  
  
  getFeatProducts()
  {
    var func =   {"function": "GETFEATUREPRDS"};
    // Object.assign(func,{"categoryIdArr":catArr}); 
     return this.http.post(environment.endPoint,func);
  }
  getKeyProducts()
  {
    var func =   {"function": "GETKEEYMARKETELEMENTS"};
    // Object.assign(func,{"categoryIdArr":catArr}); 
     return this.http.post(environment.endPoint,func);
  }


  getAllProductsAdmin()
  {
    var func =   {"function": "GETALLPRODUCTSADMIN"};
    // Object.assign(func,{"categoryIdArr":catArr}); 
     return this.http.post(environment.endPoint,func);
  }
  editProduct(id,formValue)
  {
    var func =   {"function": "EDITPRODUCT","id":id,"data":formValue.value};
    // Object.assign(func,{"categoryIdArr":catArr}); 
     return this.http.post(environment.endPoint,func);
  }

  addProduct(formValue)
  {
    // var func =   {"function": "ADDPRODUCT"};
    // Object.assign(formValue,{"function": "ADDPRODUCT"}); 
    // formValue.append("function","ADDPRODUCT");
     return this.http.post(environment.endPoint,formValue);
  }

  editPrdImage(formValue)
  {
    return this.http.post(environment.endPoint,formValue);
  }

  getProductbyId(id)
  {
    
    var func =   {"function": "GETPRODUCTBYID","productId":id};
    // Object.assign(func,{"categoryIdArr":catArr}); 
     return this.http.post(environment.endPoint,func);

  }
  getConstantbyId(id)
  {
    
    var func =   {"function": "GETCONSTANTBYID","id":id};
    // Object.assign(func,{"categoryIdArr":catArr}); 
     return this.http.post(environment.endPoint,func);

  }

  getAllConstants()
  {
    
    var func =   {"function": "GETALLCONSTANTS"};
    // Object.assign(func,{"categoryIdArr":catArr}); 
     return this.http.post(environment.endPoint,func);

  }
  updateConstant(formValue,varname,param)
  {
    console.log(formValue);
    
    
    var func =   {"function": "UPDATECONSTANT",
    "varname":varname,
  "param":param,
"value":formValue.value.value};
    // Object.assign(func,{"categoryIdArr":catArr}); 
     return this.http.post(environment.endPoint,func);

  }
}
