import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { Product } from '../products/products.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  feaPrdList:Product[];
  feaLoad:boolean;
  keyMarkLoad:boolean;
  keyMarkPrdList:Product[];
  imgUrl:string;
  constructor(private api:ApiService) { 
    
  }

  ngOnInit() {
    this.imgUrl = environment.imgUrl;
    console.log(this.imgUrl);
    
    this.feaLoad = false;
    this.feaPrdList = new Array<Product>();
    this.api.getFeatProducts().subscribe((response:any)=>{
      if(response.result)
      {
        for(let i=0;i<response.value.length;i++)
        {
      let prd = new Product();
      prd.productName = response.value[i].productname;
      prd.imageUrl = response.value[i].imageName;
      this.feaPrdList.push(prd);
        }
        this.feaLoad = true;
      }
    });

    this.keyMarkLoad = false;
    this.keyMarkPrdList = new Array<Product>();
    this.api.getKeyProducts().subscribe((response:any)=>{
      if(response.result)
      {
        for(let i=0;i<response.value.length;i++)
        {
      let prd = new Product();
      prd.productName = response.value[i].productname;
      prd.imageUrl = response.value[i].imageName;
      this.keyMarkPrdList.push(prd);
        }
        this.keyMarkLoad = true;
      }
    });
  }

}
