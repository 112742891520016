import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { Product } from '../products/products.component';

@Component({
  selector: 'app-maintain-products',
  templateUrl: './maintain-products.component.html',
  styleUrls: ['./maintain-products.component.scss']
})
export class MaintainProductsComponent implements OnInit {

  productList:Product[];
  prdLoad:boolean;
  constructor(private api:ApiService) { }

  ngOnInit() {

    this.productList = new Array<Product>();
    this.prdLoad = false;
    this.api.getAllProductsAdmin().subscribe((response:any)=>{
      if(response.result)
      {
        for(let i=0;i<response.value.length;i++)
        {
          let prd = new Product();
          prd.productId = response.value[i].productid;
          prd.productName = response.value[i].productname;
          prd.description = response.value[i].description;
          prd.dline1 = response.value[i].dline1;
          prd.dline2 = response.value[i].dline2;
          prd.dline3 = response.value[i].dline3;
          prd.status = response.value[i].status;
          prd.category = response.value[i].categoryname;
          this.productList.push(prd);
        }
      }
      this.prdLoad = true;
    });
  }

}
